import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { Row, Col, Card, Button, Image } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { sortBy } from 'lodash';
import { useUser } from "../contexts/user";
import { useSatellite } from '../contexts/SatelliteContext';
//components
import SatelliteCard from '../components/SatelliteCard';
import Poi from '../components/Poi';
import ModalEditData from '../components/ModalEditDataV2.0';
import NotFound from '../components/NotFound';
import OverlayElement from '../components/OverlayElement';
//utils
import { timeDifference, getScheme, getSection } from '../utils/Utils';
//css
import "../css/OverlayElement.css";
//icons
import { HiOutlineCube } from "react-icons/hi2";
import { CgDetailsMore } from "react-icons/cg";
import { GiLightningFrequency } from "react-icons/gi";
import { MdOutlineSatelliteAlt } from "react-icons/md";
import { FiEdit } from 'react-icons/fi';
import { GiChart } from 'react-icons/gi';
import { FaCrosshairs } from 'react-icons/fa';
import { LiaRocketSolid } from "react-icons/lia";
import { GiOrbit } from "react-icons/gi";
import { LuChevronUp, LuChevronDown, LuInfo, LuOrbit } from "react-icons/lu";
import { BsCheckCircle } from "react-icons/bs";

const Dossier = () => {
    const { isLoggedIn, isTokenResolved } = useUser();
    const { id } = useParams();
    const { fetchSatData } = useSatellite();
    const [notFound, setNotFound] = useState(false);
    const [sat, setSat] = useState(null);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showAssocSatList, toggleShowAssocSatList] = useState(false);
    const [associatedSatellites, setAssociatedSatellites] = useState({
        satList: [],
        formattedSats: [],
        total: 0
    });
    const [showMessage, setShowMessage] = useState({});

    const onSatUpdate = useCallback(async () => {
        try {
            const data = await fetchSatData([id], true);
            setSat(data[id]);
        } catch (e) {
            console.error("Failed to update satellite:", e);
        }
    }, [id, fetchSatData]);

    // Add this memoized value near the top of the component
    const memoizedSortedSchemas = useMemo(() => {
        if (!sat?.Data) return {};
        const schemas = {};
        
        // Pre-sort all schemas by section
        Object.entries(sat.Data).forEach(([key, value]) => {
            // Add null checks for value array and its items
            if (!Array.isArray(value)) return;
            
            schemas[key] = sortBy(value, item => {
                if (!item || !item.UpdatedAt) return 0;
                return Date.parse(item.UpdatedAt);
            }).reverse();
        });
        return schemas;
    }, [sat?.Data]);

    // Automatically renders schema in two columns based on section (for regular sections)
    const renderDetailsInTwoColumns = (detailsArray) => {
        if(detailsArray[0].Value === "N/A"){return <>No Data</>}
        const midpoint = Math.ceil(detailsArray.length / 2);
        const leftColumn = detailsArray.slice(0, midpoint);
        const rightColumn = detailsArray.slice(midpoint);

        return (
            <Row>
                <Col md={6}>
                    {leftColumn.map((schema) => (
                        <Row key={`${schema.Id}-${schema.Scheme}`} style={{marginTop: "10px"}}>
                            <Col sm="4"><strong>{schema.Scheme}</strong></Col>
                            <Col sm="7">{getSourceAndValue([schema], schema.Id)}</Col>
                        </Row>
                    ))}
                </Col>
                <Col md={6}>
                    {rightColumn.map((schema) => (
                        <Row key={`${schema.Id}-${schema.Scheme}`} style={{marginTop: "10px"}}>
                            <Col sm="4"><strong>{schema.Scheme}</strong></Col>
                            <Col sm="7">{getSourceAndValue([schema], schema.Id)}</Col>
                        </Row>
                    ))}
                </Col>
            </Row>
        );
    };

    // Auto renders cards for WEZ data
    const renderWezInfo = (wezData) => {
        if(wezData[0].Value === "N/A"){return <><div>No Data</div></>}
        return (
            wezData.reverse().map((wez, index) => {
                let parseVal;
                if (typeof wez.Value === "string") {
                    try {
                        parseVal = JSON.parse(wez.Value);
                    } catch (error) {
                        console.error("Failed to parse detail.Value as JSON:", error);
                        parseVal = {};
                    }
                }
                const keys = Object.keys(parseVal);
                return (
                    <Col key={`wez-${index}-${wez.Scheme}-${parseVal.name}`} className="mb-3" sm={12} lg={6}>
                        <Card style={{height:"100%"}}>
                            <Card.Body style={{paddingInline:"1.5rem"}}>
                                <Row className="mb-2">
                                    <Col style={{ minWidth: "75%" }}>
                                        <Card.Title style={{ paddingTop: "10px", fontWeight: 'bold', display: 'flex', alignItems: 'center', justifyContent: 'space-between', }}>
                                            <span className="pb-2" style={{borderBottom:"dotted 1px", borderBottomColor:"#ffffff16",}}>
                                                {wez.Scheme}<sup className='style-source'>
                                                    <OverlayElement
                                                        text={` [Source] `} // "[Source]" or "[Source] (<#>)"
                                                        element={<div className="px-1"> <Link className="style-link" to={wez.Source}>{wez.Source}</Link><br /></div>}

                                                    />
                                                </sup>
                                            </span>
                                        </Card.Title>
                                        {/* Name */}
                                        <Row style={{ marginBottom: "15px" }}>
                                            <Col><b>{parseVal.name}</b></Col>
                                        </Row>
                                    </Col>
                                    
                                    <Col style={{ minWidth: "fit-content", textAlign: "center" }}>
                                        <Image
                                            src={(() => {
                                                try {
                                                    return require(`../assets/images/WEZIcons/${wez.Scheme}.png`);
                                                } catch {
                                                    return require('../assets/images/WEZIcons/Unknown.png');
                                                }
                                            })()}
                                        /> 
                                    </Col>
                                </Row>
                                <Row>
                                    <Col><Row>
                                        {keys.slice(1).map((key, keyIndex) => ( // Exclude the first element (Name)
                                            <Col xs={12} sm={6} key={`wez-detail-${index}-${wez.Scheme}-${key}-${keyIndex}`}>
                                                <div style={{display:"inline-flex", marginBottom:"1rem", borderBottom:"dotted 1px", borderBottomColor:"#ffffff16",}}>
                                                    <div>{key}: </div>
                                                    <div style={{ marginLeft:"1rem", paddingRight:"auto"}}>{parseVal[key]}</div>
                                                </div>
                                            </Col>
                                        ))}
                                    </Row></Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                )
            })
        )
    };

    const sortSchema = useCallback((unsortSchema) => {
        const schemaKey = Object.keys(sat?.Data || {}).find(key => 
            sat.Data[key] === unsortSchema
        );
        
        if (schemaKey && memoizedSortedSchemas[schemaKey]) {
            return memoizedSortedSchemas[schemaKey];
        }
        return sortBy(unsortSchema, item => {
            if (!item || !item.UpdatedAt) return 0;
            return Date.parse(item.UpdatedAt);
        }).reverse();
    }, [sat?.Data, memoizedSortedSchemas]);

    // Fetch associated satellites when sat changes
    useEffect(() => {
        const fetchAssociatedSatellites = async () => {
            if (!sat?.Data) return;

            const assocSats = sortSchema(getScheme(sat.Data, ["Associated Satellites"]))[0].Value;
            if (assocSats === "N/A") {
                setAssociatedSatellites({ satList: [], formattedSats: [], total: 0 });
                return;
            }

            const satList = await fetchSatData(assocSats.split(',').map(s => s.trim()));
            const formattedSats = Object.keys(satList).map(ida => ({
                id: ida,
                name: satList[parseInt(ida)]?.Name || " "
            }));

            setAssociatedSatellites({
                satList,
                formattedSats,
                total: Object.keys(satList).length
            });
        };

        fetchAssociatedSatellites();
    }, [sat, fetchSatData, sortSchema]); // Dependencies include sat and fetchSatData

    useEffect(() => {
        const fetchData = async () => {
            if (id && isTokenResolved) {
                try {
                    const data = await fetchSatData([id]);
                    if (data) {
                        setSat(data[id]);
                        setNotFound(false);
                    } else {
                        setNotFound(true);
                    }
                } catch (error) {
                    console.error("Error fetching satellite data:", error);
                    setNotFound(true);
                }
            }
        };

        fetchData();
    }, [id, fetchSatData, isTokenResolved]); // Added isTokenResolved to the dependencies

    const toggleEditModal = () => setShowEditModal(!showEditModal);



    const openSpacefana = () => {
        const baseUrl = (() => {
            const hostname = window.location.hostname;
            return (hostname === "localhost" || hostname === "saberdevusa.xyz")
                ? "spacefana.saberdevusa.xyz"
                : "spacefana.saberastro.com";
        })();
        const src = `https://${baseUrl}/d/PO81TfmVk/elsethistory?orgId=1&var-satnos=${sat.SatNo}&var-udlp=&var-showSourceColor=Yes&var-lines=Yes&var-accesstoken=&var-stdDev=3&var-showWindu=Yes&from=1715977837024&to=1716582637025&panelId=2`;
        window.open(src, '_blank', 'noopener,noreferrer');
    };

    const openSpacecockpit = () => {
        const baseUrl = (() => {
            const hostname = window.location.hostname;
            return (hostname === "localhost" || hostname === "saberdevusa.xyz")
                ? "https://spacecockpit.saberdevusa.xyz/?"
                : "https://spacecockpit.saberastro.com/?";
        })();

        const cleanedSatellites = [sat?.SatNo, ...(Array.isArray(associatedSatellites.satList) ? associatedSatellites.satList.map(s => s.SatNo) : [])].filter(Boolean).join(',');
        const satParams = `SID=${cleanedSatellites}`;
        const fullUrl = baseUrl + satParams;

        window.open(fullUrl, '_blank', 'noopener,noreferrer');
    };

    /// returns ordered html of source links
    const getSource = (sortedSchema, schemaId) => {
        const validatedSources = sortedSchema[0].ValidatedSources;
        let urls = [];
        if(validatedSources){
            try{
                urls = JSON.parse(validatedSources);
            } catch (error) {
                console.error("Invalid JSON string", error);
            }
            if (!Array.isArray(urls)) {
                console.error("Parsed data is not an array");
            }
        }
        if (!sortedSchema[0].Source) {
            return sortedSchema[0].Value !== "N/A"
            ?  <>
                    <sup style={{ color:"red" }}>
                        <OverlayElement 
                            text={` [Source] `}
                            element={<div className="ps-1">No source(s) provided.</div>}
                        />
                    </sup>
                </>
            : <></>
        }
        let sourceText = [];
        sortedSchema.forEach(scheme => {if (scheme.Value === sortedSchema[0].Value) {sourceText.push(scheme.Source)}})
        return  <>
                    <sup className='style-source'>
                        <OverlayElement 
                            text={` [Source] ${sourceText.length === 1 ? "" : `(${sourceText.length})`}`}
                            element={sourceText.map((src, id) => (
                                <div key={`source-${id}-${src}`} style={{ textAlign: 'left' }}>
                                        <Link className="style-link" to={src} target="_blank" rel="noopener noreferrer">
                                            {src.length > 20 ? `${src.slice(0, 30)}...` : src}
                                        </Link>
                                    <br/>
                                </div>
                            ))}
                        />
                    </sup>
                    {(sortedSchema[0].VerifiedBy.includes("gpt-4")) && (
                <>
                    <sup>
                        <BsCheckCircle
                            variant="outline-success"
                            style={{ fontSize: '0.85rem', color: 'green', cursor: 'pointer' }}
                            onClick={() => setShowMessage(prev => ({ ...prev, [schemaId]: !prev[schemaId] }))}
                        >
                        </BsCheckCircle>
                    </sup>
                    {showMessage[schemaId] && (
                        
                        <>
                        <OverlayElement
                            element={"text"}
                        />
                            <div style={{
                                // display: "flex",
                                border: '1px solid #79ed8e',
                                borderRadius: '5px',
                                background: '#181b1f',
                                padding: '5px',
                                width: '300px',
                                display: 'inline-block',
                                position: 'absolute',
                                zIndex: 1000
                            }}>
                                This data has been verified by ChatGPT o1 Preview. 
                                <br></br>
                                {sortedSchema[0].Validated >= 0.75 && sortedSchema[0].ValidatedSources.length > 2 && (
                                    <div>
                                        ChatGPT also found the following corroborating sources: <br></br>
                                        {urls.map((url, index) => (
                                            <a href={url} key={index} target="_blank" rel="noopener noreferrer">
                                                {url.length > 20 ? `${url.slice(0, 30)}...` : url}<br></br>
                                            </a>
                                        ))}
                                    </div>
                                )}
                                
                            </div>
                        </>
                    )}
                </>
            )}
                </>;
    }

    /// Wrapper to get most recent scheme value and sources.
    const getSourceAndValue = (schema, schemaId) => {
        const sortedSchema = sortSchema(schema);
        return <>
            {sortedSchema[0].Value}{getSource(sortedSchema, schemaId)}
            
        </>;
    }

    const description = sat ? `${sat.Name} is a satellite with NORAD ID ${sat.SatNo}. It was launched on ${sat.LaunchDate}.` : "No satellite data available.";

    return (
        <>
            {notFound && (
                <NotFound
                    id={id}
                >
                </NotFound>
            )}
            {sat?.Data && (
                <div style={{ padding: "20px" }}>
                    <Helmet>
                        <title>{`(${sat.SatNo}) ${sat.Name}`}</title>
                        <meta name="satellite-name" content={sat ? `Satellite: ${sat.Name}` : "Satellite Details"} />
                        <meta property="description" content={description} />
                    </Helmet>
                    <h2 style={{marginBottom: "15px"}}>{sat.SatNo} - {sat.Name}</h2>
                    {/* Edit Data Button */}
                    {isLoggedIn && (
                        <div className="d-flex flex-column flex-md-row align-items-md-start gap-3 mb-4">
                            <div className="mb-2" style={{ display: 'flex', gap: '20px' }}>
                                <Button variant="outline-primary" onClick={toggleEditModal} style={{ borderColor: 'white', color: 'white' }}>
                                    <FiEdit /> Edit Data
                                </Button>
                                <Button onClick={openSpacefana} variant="outline-primary" style={{ borderColor: 'white', color: 'white' }}>
                                    <GiChart /> Elset History
                                </Button>
                                <Button onClick={openSpacecockpit} variant="outline-primary" style={{ borderColor: 'white', color: 'white' }}>
                                    <LuOrbit /> Orbital View
                                </Button>
                            </div>
                        </div>
                    )}
                    <div style={{ fontStyle: "italic", color: "#ffc107", marginTop: "10px", marginBottom: "10px" }}>
                        <LuInfo />
                        This is publicly available information and was not provided through Intel channels.
                    </div>
                    {/* Edit Modal Data */}
                    <ModalEditData
                        show={showEditModal}
                        setShow={setShowEditModal}
                        selectedSat={sat}
                        onSatUpdate={onSatUpdate}
                    />
                    <br></br>
                    <Row className="flex-container">
                        <Col md={8} className="mb-3 order-2 order-md-1">
                            

                            {/* Summary */}
                            <Card className="mb-3">
                                <Card.Body>
                                    <Card.Title id="summary" style={{ color: "#ffc107" }}>
                                        Summary
                                    </Card.Title>
                                    {getSourceAndValue(getScheme(sat?.Data, ["Overall Summary"]), "overall-summary")}
                                </Card.Body>
                            </Card>

                            {/* Orbit */}
                            <Card className="mb-3">
                                <Card.Body>
                                    <Card.Title style={{ color: "#ffc107" }}><GiOrbit /> Orbit</Card.Title>
                                    <Row>
                                        <Col sm="6">
                                            <Row style={{marginTop: "10px"}}>
                                                <Col sm="4"><strong>Last Seen (18th SPCS)</strong></Col>
                                                <Col>[{timeDifference(sat?.Epoch)}] {sat?.Epoch}</Col>
                                            </Row>
                                            <Row style={{marginTop: "10px"}}>
                                                <Col sm="4"><strong>Inclination</strong></Col>
                                                <Col>{sat?.Inclination}</Col>
                                            </Row>

                                        </Col>
                                        <Col sm="6">
                                            <Row style={{marginTop: "10px"}}>
                                                <Col sm="4"><strong>Longitude (°E)</strong></Col>
                                                <Col>{sat?.Longitude}</Col>
                                            </Row>
                                            <Row style={{marginTop: "10px"}}>
                                                <Col sm="4"><strong>Longitude Drift (°E/day)</strong></Col>
                                                <Col>{sat?.LonDriftDegreesPerDay}</Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>

                            {/* lAUNCH_DETAILS Automated */}
                            <Card className="mb-3">
                                <Card.Body>
                                    <Card.Title style={{ color: "#ffc107" }}><LiaRocketSolid /> Launch Details</Card.Title>
                                    <Row  style={{paddingBottom: "10px", marginBottom: "5px"}}>
                                        <Col>
                                        {getScheme(sat?.Data, ["Launch Summary"])[0].Value === "N/A"
                                            ? ""
                                            : getSourceAndValue(getScheme(sat?.Data, ["Launch Summary"]), "launch-summary")}
                                        </Col>
                                    </Row>
                                    
                                    {renderDetailsInTwoColumns(getSection(sat?.Data, "LAUNCH_DETAILS"))}

                                    <Row className="mt-2 pt-2" style={{borderTop:"dotted 1px", borderTopColor:"#ffffff16",}}>
                                    <span><strong>Launch History for Similar</strong></span>
                                        <Col>{getSourceAndValue(getScheme(sat?.Data, ["Launch History for Similar"]), "launch-history")}</Col>
                                    </Row>

                                </Card.Body>
                            </Card>

                            {/* MISSION_DETAILS Automated */}
                            <Card className="mb-3">
                                <Card.Body>
                                    <Card.Title style={{ color: "#ffc107" }}><CgDetailsMore /> Mission Details</Card.Title>
                                    {getScheme(sat?.Data, ["Details Description"])[0].Value !== "N/A" && (
                                        <div style={{paddingBottom: "10px", marginBottom: "5px"}}>
                                            {getSourceAndValue(getScheme(sat.Data, ["Details Description"]), "details-description")}
                                        </div>
                                    )}
                                    
                                    {renderDetailsInTwoColumns(getSection(sat?.Data, "MISSION_DETAILS"))}
                                    
                                    {/* Associated Satellites */}
                                    <Row style={{ marginTop: '.6rem', paddingTop: '.7rem', borderTop:"dotted 1px", borderTopColor:"#ffffff16",}}>
                                        <Col sm="4">
                                            <span><strong>Associated Satellite(s)</strong></span> {getSource(getScheme(sat?.Data, ["Associated Satellites"]))}
                                        </Col>
                                        {associatedSatellites.total > 0 && (
                                            <Col sm="4">
                                                {(!showAssocSatList
                                                    ? associatedSatellites.formattedSats.slice(0, 3).map(({ id, name }) => (
                                                        <div key={`assoc-sat-${id}`}>
                                                            <Link className="style-link" to={`/dossier/${id}`} style={{ color: '#ffc107' }}>
                                                                {id}
                                                            </Link> - {name}
                                                        </div>
                                                    ))
                                                    : associatedSatellites.formattedSats.map(({ id, name }) => (
                                                        <div key={`assoc-sat-${id}`}>
                                                            <Link className="style-link" to={`/dossier/${id}`} style={{ color: '#ffc107' }}>
                                                                {id}
                                                            </Link> - {name}
                                                        </div>
                                                    )))}
                                                
                                                {associatedSatellites.total > 3 && (
                                                    <>
                                                        {!showAssocSatList && (
                                                            <div style={{ fontSize: ".75rem", color: 'lightGray' }}>
                                                                + {associatedSatellites.total - 3} more...
                                                            </div>
                                                        )}
                                                        <Button 
                                                            variant="outline-warning" 
                                                            className='mt-2 btn-outline-warning' 
                                                            onClick={() => toggleShowAssocSatList(!showAssocSatList)}
                                                        >
                                                            {showAssocSatList ? <><LuChevronUp /> Collapse</> : <><LuChevronDown /> Expand</>}
                                                        </Button>
                                                    </>
                                                )}
                                            </Col>
                                        )}
                                        {associatedSatellites.total === 0 && (
                                            <Col sm="4">N/A</Col>
                                        )}
                                    </Row>
                                </Card.Body>
                            </Card>

                            {/* Characteristics automated */}
                            <Card className="mb-3">
                                <Card.Body>
                                    <Card.Title style={{ color: "#ffc107" }}><HiOutlineCube /> Characteristics</Card.Title>
                                    {renderDetailsInTwoColumns(getSection(sat?.Data, "CHARACTERISTICS"))}
                                </Card.Body>
                            </Card>

                            {/* Frequencies and Comms automated */}
                            <Card className="mb-3">
                                <Card.Body>
                                    <Card.Title style={{ color: "#ffc107" }}><GiLightningFrequency /> Frequencies & Comms</Card.Title>
                                    {getScheme(sat?.Data, ["Comms Description"])[0].Value !== "N/A" && (
                                        <div style={{paddingBottom: "10px", marginBottom: "5px"}}>
                                            {getSourceAndValue(getScheme(sat.Data, ["Comms Description"]), "comms-description")}
                                        </div>
                                    )}

                                    {renderDetailsInTwoColumns(getSection(sat?.Data, "FREQUENCIES_AND_COMMS"))}

                                </Card.Body>
                            </Card>

                            {/* Satellite Status automated */}
                            <Card className="mb-3">
                                <Card.Body>
                                    <Card.Title style={{ color: "#ffc107" }}><MdOutlineSatelliteAlt /> Satellite Status</Card.Title>
                                    {getScheme(sat?.Data, ["Status Description"])[0].Value !== "N/A" && (
                                        <div className="mt-2 pt-2" style={{paddingBottom: "10px", marginBottom: "5px"}}>
                                            {getSourceAndValue(getScheme(sat.Data, ["Status Description"]), "status-description")}
                                        </div>
                                    )}

                                    {renderDetailsInTwoColumns(getSection(sat?.Data, "SATELLITE_STATUS"))}

                                </Card.Body>
                            </Card>

                            {/* WEZ */}
                            {getSection(sat?.Data, "WEZ_INFO", false) && ( // Check if there is info to render
                            <Card className="mb-3">
                                <Card.Body>
                                    <Card.Title style={{ color: "#ffc107", paddingBottom: "10px" }}><FaCrosshairs /> Weapon Engagement Zones</Card.Title>
                                    <Row>
                                        {renderWezInfo(getSection(sat?.Data, "WEZ_INFO", false))}
                                    </Row>
                                </Card.Body>
                            </Card>
                            )}
                        </Col>

                        {/* Right Column */}
                        <Col md={4} className="mb-4 order-1 order-md-2">
                            {sat && (
                                <>
                                    <SatelliteCard sat={sat} />
                                    <Poi sat={sat} onSatUpdate={onSatUpdate}/>
                                </>
                            )}
                        </Col>
                    </Row>
                </div>
            )}
        </>
    );
};

export default Dossier;
