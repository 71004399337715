import React, { useState, Suspense, lazy } from 'react';
import { Card, Button, Image, Figure, FigureImage, FigureCaption } from 'react-bootstrap';
import "../css/cards.css";
import OverlayText from './OverlayText';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { getFlagLink, getImages } from '../utils/Utils';
import { LuClipboardCopy } from "react-icons/lu";
import { imgPlaceholderPath } from '../utils/constants';

const LazyCarousel = lazy(() => import('react-responsive-carousel').then(module => ({
  default: module.Carousel
})));

const SatelliteCardImage = ({
    sat,
}) => {
    const [showImage, setShowImage] = useState(true);

    const handleCopy = async (text) => {
        try {
            await navigator.clipboard.writeText(text);
            alert(`Link Copied:\n ${text}`);
        } catch (error) {
            alert("Failed to Copy Link.");
            console.error('Failed to copy!', error);
        }
    }

    const hideImage = () => {
        setShowImage(false);
    }

    return (
        <>
            <Card
                style={{
                    backgroundColor: "#181b1f",
                    color: "white",
                    borderRadius: "0",
                    border: "0",
                }}
            >
                <Suspense fallback={<div className="loading-placeholder">Loading images...</div>}>
                    <LazyCarousel
                        showThumbs={false}
                        showIndicators={false}
                    >
                        {getImages(sat).map((images) => 
                            <Figure className="mb-0" key={images.Value}>
                                <FigureImage
                                    className="mb-0"
                                    style={{
                                        width: "100%",
                                        height: "auto",
                                        objectFit: "contain",
                                        minHeight: "200px",
                                        maxHeight: "50vh"
                                    }}
                                    src={images.Value.startsWith('http://') || images.Value.startsWith('https://') ? images.Value : imgPlaceholderPath}
                                    alt={sat?.Name}
                                />
                                {images.Source &&
                                    <FigureCaption className='pb-0 mt-2'>
                                        <OverlayText text={images.Source} maxLength={40} />
                                        <Button
                                            className='btn-copy'
                                            title='Copy Link'
                                            style={{ paddingTop: '0', paddingBottom: '0', marginLeft: '5px', marginRight: 'auto' }}
                                            onClick={() => handleCopy(images.Source)}>
                                            <LuClipboardCopy />
                                        </Button>
                                    </FigureCaption>
                                }
                            </Figure>
                        )}
                    </LazyCarousel>
                </Suspense>

                {showImage && (
                    <Image
                        style={{
                            width: "50px",
                            height: "50px",
                            position: 'absolute',
                            zindex: 9999,
                            top: 0,
                            left: 5
                        }}
                        alt={sat?.CountryId}
                        src={getFlagLink(sat)}
                        onError={hideImage}
                    />
                )}

            </Card>
        </>
    );
};

export default SatelliteCardImage;
