const url = window.location.host.replace("probe","nps").replace(":3000",":9001").replace("3001","9001");
const uri = `${ (url.includes("localhost") ? "http://" : "https://")}${url}`;

const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

export const sendCreateRequest = async (endpoint, data, headers = {}) => {
    try {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                ...headers,
            },
            body: JSON.stringify(data),
            credentials: 'include', // Include httpOnly cookies in the request
        };

        const response = await fetch(`${uri}${endpoint}`, requestOptions);

        if (!response.ok) {
            const errorDetails = await response.json();
            throw new Error(`Error ${response.status}: ${errorDetails.message || response.statusText}`);
        }
        console.log('Create request successful');
        return await response.json();
    } catch (error) {
        console.error('Error during sendCreateRequest:', error);
        return { success: false, error: error.message };
    }
};

export const sendReadRequest = async (endpoint, headers = {}, queries = {}, retries = 3, delayTime = 1000) => {
    try {

        const queryString = new URLSearchParams(queries).toString();
        const urlWithQuery = `${uri}${endpoint}?${queryString}`;

        const requestOptions = {
            method: 'GET',
            headers: {
                'api_key': `${process.env.REACT_APP_API_KEY}`,
                ...headers,
            },
            credentials: 'include',
        };
        const response = await fetch(urlWithQuery, requestOptions);

        if (!response.ok) {
            const errorDetails = await response.json();
            if (response.status === 401) {
                throw new Error(`Error ${response.status}: ${errorDetails.message || response.statusText}`);
            }
            throw new Error(`Error ${response.status}: ${errorDetails.message || response.statusText}`);
        }

        console.log('Read request successful');
        return await response.json();
    } catch (error) {
        if (error.message.includes('Error 401')) {
            console.error('Unauthorized:', error);
            return { success: false, error: error.message };
        }
        if (retries > 0) {
            console.error(`Error during sendReadRequest: ${error.message}. Retrying...`);
            await delay(delayTime);
            return sendReadRequest(endpoint, headers, queries, retries - 1, delayTime);
        } else {
            console.error('Max retries reached. Failing request:', error);
            return { success: false, error: error.message };
        }
    }
};

export const sendUpdateRequest = async (endpoint, data, headers = {}) => {
    try {
        const requestOptions = {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                ...headers,
            },
            body: JSON.stringify(data),
            credentials: 'include',
        };

        const response = await fetch(`${uri}${endpoint}`, requestOptions);

        if (!response.ok) {
            const errorDetails = await response.json();
            throw new Error(`Error ${response.status}: ${errorDetails.message || response.statusText}`);
        }

        console.log('Update request successful');
        return await response.json();
    } catch (error) {
        console.error('Error during sendUpdateRequest:', error);
        return { success: false, error: error.message };
    }
};

export const sendDeleteRequest = async (endpoint, headers = {}) => {
    try {
        const requestOptions = {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                ...headers,
            },
            credentials: 'include',
        };

        const response = await fetch(`${uri}${endpoint}`, requestOptions);

        if (!response.ok) {
            const errorDetails = await response.json();
            throw new Error(`Error ${response.status}: ${errorDetails.message || response.statusText}`);
        }

        console.log('Delete request successful');
        return await response.json();
    } catch (error) {
        console.error('Error during sendDeleteRequest:', error);
        return { success: false, error: error.message };
    }
};

const sendSearchRequest = async (endpoint, data, headers = {}) => {
    try {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'api_key': `${process.env.REACT_APP_API_KEY}`,
                ...headers,
            },
            body: JSON.stringify(data),
            credentials: 'include'
        };

        const response = await fetch(`${uri}${endpoint}`, requestOptions);

        if (!response.ok) {
            const errorDetails = await response.json();
            throw new Error(`Error ${response.status}: ${errorDetails.message || response.statusText}`);
        }

        console.log('Search request successful');
        return await response.json(); // Return the response like a GET request
    } catch (error) {
        console.error('Error during sendPostRequest:', error);
        return { success: false, error: error.message };
    }
};


// vvv Request helper functions vvv

export const createSatRow = async (data) => {
    const response = await sendCreateRequest("/pair", data);
    return response;
}

export const getSatNos = async () => {
    const response = await sendReadRequest("/pair/noradIds");
    return response;
}

export const getSatData = async (satNos) => {
    const query = {
        'satno': satNos,
    };
    const response = await sendReadRequest("/pair", {}, query, 3, 1000); // Retry 3 times with 1 second delay
    return response;
}

export const updateSatRow = async (rowId, data) => {
    const response = await sendUpdateRequest(`/pair/${rowId}`, data);
    return response;
}

export const deleteSatRow = async (rowId) => {
    const response = await sendDeleteRequest(`/pair/${rowId}`);
    return response;
}

export const getUser = async () => {
    const response = await sendReadRequest("/user-details");
    return response;
}
/**
 * 
 * @param {Object} filters 
 * @param {Array<String>} searchStrings 
 * @returns {Array<Number>}
 */
export const searchSats = async (filters, searchStrings) => {
    const body = {
        filters:{...filters},
        search:[...searchStrings]
    };
    const response = await sendSearchRequest("/pair/search", body);
    return response;
}

export const accessTokenRequest = async (accessToken) => {
    const headers = {
        access_token: accessToken,
    }
    try {
        const response = sendLoginRequest(headers);
        return response;
    } catch (error){
        console.error('Error during login:', error);
        return null;
    }
}

export const sendLoginRequest = async (credentials = {}) => {
    try {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                ...credentials,
            },
            credentials:'include',
        };

        const response = await fetch(`${uri}/login`, requestOptions);

        if (!response.ok) {
            const errorDetails = await response.json();
            throw new Error(`Login failed: ${errorDetails.message || response.statusText}`);
        }

        const data = await response.json();

        console.log('Login request successful');
        return data;
    } catch (error) {
        console.error('Error during login:', error);
        return { success: false, error: error.message };
    }
};

export const sendLogoutRequest = async (credentials = {}) => {
    try {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                ...credentials,
            },
            credentials:'include'
        };

        const response = await fetch(`${uri}/logout`, requestOptions);

        if (!response.ok) {
            const errorDetails = await response.json();
            throw new Error(`Logout failed: ${errorDetails.message || response.statusText}`);
        }

        const data = await response.json();

        console.log('Logout request successful');
        return data;
    } catch (error) {
        console.error('Error during logout:', error);
        return { success: false, error: error.message };
    }
};