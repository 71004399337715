import React, { useState } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import { useUser } from "../contexts/user";

const SearchModal = ({ 
    show, 
    setClose,
    addFilter, 
    filters, 
    removeFilter
}) => {
    const { isLoggedIn } = useUser();
    const [parameter, setParameter] = useState('--');
    const [operator, setOperator] = useState('=');
    const [valueStart, setValueStart] = useState('');
    const [valueEnd, setValueEnd] = useState('');
    const [value, setValue] = useState('');

    const handleAddFilter = () => {
        if (parameter && parameter !== "--") {
            const filterValue = operator === "range" 
                ? { start: String(valueStart), end: String(valueEnd) }
                : String(value);

            const newFilter = { 
                [parameter]: { 
                    param: parameter,
                    operator: operator, 
                    value: filterValue,
                } 
            };
            addFilter(newFilter);
            handleClose();
        } else {
            alert("Please select a valid parameter before adding a filter.");
        }
    };

    const handleClose = () => {
        setClose(false);
        setParameter('--');
        setOperator('=');
        setValue('');
        setValueStart('');
        setValueEnd('');
    }

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Apply Filters</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3">
                        <Form.Label>Parameter</Form.Label>
                        <Form.Select
                            value={parameter}
                            onChange={(e) => setParameter(e.target.value)}
                            required
                        >
                            <option>--</option>
                            <option value={"CountryId"}>Country of Launch</option>
                            <option value={"LaunchDate"}>Launch Date</option>
                            <option value={"SatNo"}>Sat Number/Norad ID Range</option>
                            <option>Regime</option>
                            <option value={"ObjectType"}>Object Type</option>
                            <option>Epoch</option>
                            <option>Inclination</option>
                            <option>Longitude</option>
                            <option value={"LonDriftDegreesPerDay"}>Longitude Drift Degress</option>
                            <option value={"Raan"}>RAAN</option>
                            {isLoggedIn && (<option value={"Rank"}>HrrRank</option>)}
                        </Form.Select>
                    </Form.Group>
                    

                    {/* NAME */}
                    {parameter === "Name" &&(
                        <Form.Group>
                            <Form.Label>Satellite Name</Form.Label>
                            <Form.Control
                                    type="text"
                                    placeholder='Enter Satellite Name'
                                    value={value}
                                    onChange={(e) => setValue(e.target.value)}
                            />
                        </Form.Group>
                    )}

                    {/* COUNTRY ID */}
                    {parameter === "CountryId" &&(
                        <Form.Group>
                            <Form.Label>Country Name</Form.Label>
                            <Form.Select
                                value={value}
                                onChange={(e) => setValue(e.target.value)}
                            > 
                                <option>--</option>
                                <option value={"CN"}>China</option>
                                <option value={"DE"}>Germany</option>
                                <option value={"IR"}>Iran</option>
                                <option value={"JP"}>Japan</option>
                                <option value={"PK"}>Pakistan</option>
                                <option value={"RU"}>Russia</option>
                                <option value={"US"}>United States</option>

                                {/* <option>--</option>
                                {countries.map((country) => (
                                    <option key={country.code} value={country.code}>
                                        {country.name}
                                    </option>
                                ))} */}
                            </Form.Select>
                        </Form.Group>
                    )}

                    {/* LAUNCH DATE */}
                    {parameter === "LaunchDate" &&(
                        <Form.Group>
                            <Form.Label>Date</Form.Label>
                            <Form.Control
                            type="date"
                            value={value}
                            onChange={(e) => setValue(e.target.value)}
                            />  
                        </Form.Group>
                    )}

                    {/* NORAD ID RANGE */}
                    {parameter === "SatNo" &&(
                        <Form.Group>
                            <Form.Label>Operator</Form.Label>
                                <Form.Select
                                    className="mb-3"
                                    value={operator}
                                    onChange={(e) => setOperator(e.target.value)}
                        >
                                    <option>=</option>
                                    <option>!=</option>
                                    <option>&lt;</option>
                                    <option>&gt;</option>
                                    <option>&lt;=</option>
                                    <option>&gt;=</option>
                                    <option>range</option>
                                </Form.Select>
                            {/* Hide input if operator is 'range' */}
                            {operator !== "range" && (
                                <>
                                    <Form.Label>Enter 5 Digit Norad ID</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder='XXXXX'
                                        value={value}
                                        onChange={(e) => setValue(e.target.value)}
                                    />
                                </>
                            )}
                        </Form.Group>
                    )}  

                    {/* OBJECT TYPE */}
                    {parameter === "ObjectType" &&(
                        <Form.Group>
                            <Form.Label>Select Type</Form.Label>
                            <Form.Select
                                value={value}
                                onChange={(e) => setValue(e.target.value)}
                            > 
                                <option>--</option>
                                <option value={"PAYLOAD"}>Payload</option>
                                <option value={"ROCKET BODY"}>Rocket Body</option>
                                <option value={"DEBRIS"}>Debris</option>
                            </Form.Select>
                        </Form.Group>
                    )}

                    {/* EPOCH */}
                    {parameter === "Epoch" &&(
                        <Form.Group>
                            <Form.Label>Epoch</Form.Label>
                            <Form.Label>Enter Epoch Date and Time</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder='YYYY-MM-DD 00:00:00'
                                    value={value}
                                    onChange={(e) => setValue(e.target.value)}
                                />
                        </Form.Group>
                    )} 

                    {/* INCLINATION RANGE */}
                    {parameter === "Inclination" &&(
                        <Form.Group>
                            <Form.Label>Operator</Form.Label>
                                <Form.Select
                                    className="mb-3"
                                    value={operator}
                                    onChange={(e) => setOperator(e.target.value)}
                        >
                                    <option>=</option>
                                    <option>!=</option>
                                    <option>&lt;</option>
                                    <option>&gt;</option>
                                    <option>&lt;=</option>
                                    <option>&gt;=</option>
                                    <option>range</option>
                                </Form.Select>
                            {/* Hide input if operator is 'range' */}
                            {operator !== "range" && (
                                <>
                                    <Form.Label>Enter Inclination</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder='00.00'
                                        value={value}
                                        onChange={(e) => setValue(e.target.value)}
                                    />
                                </>
                            )}
                        </Form.Group>
                    )} 

                    {/* LONGITUDE */}
                    {parameter === "Longitude" &&(
                        <Form.Group>
                            <Form.Label>Operator</Form.Label>
                                <Form.Select
                                    className="mb-3"
                                    value={operator}
                                    onChange={(e) => setOperator(e.target.value)}
                        >
                                    <option>=</option>
                                    <option>!=</option>
                                    <option>&lt;</option>
                                    <option>&gt;</option>
                                    <option>&lt;=</option>
                                    <option>&gt;=</option>
                                    <option>range</option>
                                </Form.Select>
                            {/* Hide input if operator is 'range' */}
                            {operator !== "range" && (
                                <>
                                    <Form.Label>Enter Longitude</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder='-180.0000 to 180.0000'
                                        value={value}
                                        onChange={(e) => setValue(e.target.value)}
                                    />
                                </>
                            )}
                        </Form.Group>
                    )} 

                    {/* LONGITUDE DRIFT */}
                    {parameter === "LonDriftDegreesPerDay" &&(
                        <Form.Group>
                            <Form.Label>Operator</Form.Label>
                                <Form.Select
                                    className="mb-3"
                                    value={operator}
                                    onChange={(e) => setOperator(e.target.value)}
                        >
                                    <option>=</option>
                                    <option>!=</option>
                                    <option>&lt;</option>
                                    <option>&gt;</option>
                                    <option>&lt;=</option>
                                    <option>&gt;=</option>
                                    <option>range</option>
                                </Form.Select>
                            {/* Hide input if operator is 'range' */}
                            {operator !== "range" && (
                                <>
                                    <Form.Label>Enter Longitude Drift Degrees</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder='-180.0000 to 180.0000'
                                        value={value}
                                        onChange={(e) => setValue(e.target.value)}
                                    />
                                </>
                            )}
                        </Form.Group>
                    )} 

                    {/* RAAN */}
                    {parameter === "Raan" &&(
                        <Form.Group>
                            <Form.Label>Operator</Form.Label>
                                <Form.Select
                                    className="mb-3"
                                    value={operator}
                                    onChange={(e) => setOperator(e.target.value)}
                                >
                                    <option>=</option>
                                    <option>!=</option>
                                    <option>&lt;</option>
                                    <option>&gt;</option>
                                    <option>&lt;=</option>
                                    <option>&gt;=</option>
                                    <option>range</option>
                                </Form.Select>
                            {/* Hide input if operator is 'range' */}
                            {operator !== "range" && (
                                <>
                                    <Form.Label>Enter RAAN Degrees</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder='00.0000'
                                        value={value}
                                        onChange={(e) => setValue(e.target.value)}
                                    />
                                </>
                            )}
                        </Form.Group>
                    )} 

                    {/* REGIME INT */}
                    {parameter === "Regime" &&(
                        <Form.Group className="mb-3">
                            <Form.Label>Regime</Form.Label>
                                <Form.Select
                                    type="text"
                                    placeholder='Whole Number (ex. 1)'
                                    value={value}
                                    onChange={(e) => setValue(e.target.value)}
                                >
                                    <option value={"1"}>Undetermined</option>
                                    <option value={"2"}>Leo</option>
                                    <option value={"4"}>Heo</option>
                                    <option value={"8"}>GeoInclined</option>
                                    <option value={"16"}>Meo</option>
                                    <option value={"32"}>Molniya</option>
                                    <option value={"64"}>Sso</option>
                                    <option value={"128"}>Polar</option>
                                    <option value={"256"}>GeoStationary</option>
                                    <option value={"512"}>Graveyard</option>
                                </Form.Select>
                        </Form.Group>
                    )} 

                    {/* HRR RANK*/}
                    {parameter === "Rank" &&(
                        <Form.Group className="mb-3">
                            <Form.Label>Enter HRR Rank</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder='Whole Number (ex. 1)'
                                    value={value}
                                    onChange={(e) => setValue(e.target.value)}
                                />
                        </Form.Group>
                    )} 
                    
                    {/* Range Input Fields */}
                    {operator === "range" && (
                        <>
                            <Form.Group className="mb-3">
                                <Form.Label>Start Value</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder='Enter Start Value'
                                    value={valueStart}
                                    onChange={(e) => setValueStart(e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>End Value</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder='Enter End Value'
                                    value={valueEnd}
                                    onChange={(e) => setValueEnd(e.target.value)}
                                />
                            </Form.Group>
                        </>
                    )}

                    <br></br>

                    <div className="d-flex justify-content-between">
                        <Button variant="primary" onClick={handleAddFilter}>
                            Add Filter
                        </Button>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                    </div>
                </Form>
                <ul style={{ marginTop: '20px', paddingLeft: '0' }}>
                    {Object.entries(filters).map(([key, { operator, value, start, end }], index) => (
                        <li key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                            <span style={{ 
                                marginRight: '10px',
                                backgroundColor: '#4D5656', 
                                padding: '5px 10px', 
                                borderRadius: '5px'
                             }}>
                                {key} {operator} {operator === "range" ? `from "${start}" to "${end}"` : `"${value}"`} 
                            </span>
                            <Button variant="secondary" onClick={() => removeFilter(key)}>Remove</Button>
                        </li>
                    ))}
                </ul>
            </Modal.Body>
        </Modal>
    );
};

export default SearchModal;