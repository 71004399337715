import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Row, Col, Button, Container, Form, InputGroup, Image } from "react-bootstrap";
import { useUser } from "../contexts/user";
import { Grid } from '@mui/material';
import ModalEditData from "../components/ModalEditDataV2.0";
import ModalAddSatellite from "../components/ModalAddSatellite";
import SatelliteCard from '../components/SatelliteCard';
import SearchModal from '../components/SearchModal';
import Loading from "../components/Loading";
import { useSatellite } from '../contexts/SatelliteContext';
import { searchSats } from '../utils/Api';
import ProbeLogoPNG from "../assets/images/ProbeLogoPNG.png";
import { LuInfo, LuFilter, LuList, LuX, LuArrowUpRight, LuArrowDownRight, LuPlus } from "react-icons/lu";
import { IoIosSearch } from "react-icons/io";
import '../css/Home.css';
import { useInView } from 'react-intersection-observer';

const Home = () => {
    const BATCH = 18;
    const { isTokenResolved, isLoggedIn } = useUser();
    const { satNos, fetchSatData, isLoading } = useSatellite();
    const [filters, setFilters] = useState({});
    const [showSatModal, setShowSatModal] = useState(false);
    const [hrrChecked, setHrrChecked] = useState(false);
    const [selectedSat, setSelectedSat] = useState(null);
    const [showAddSatModal, setShowAddSatModal] = useState(false);
    const [showQueryModal, setShowQueryModal] = useState(false);
    const [searchResults, setSearchResults] = useState([]);
    const [visibleSats, setVisibleSats] = useState({});
    const [isDescending, setIsDescending] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [endIndex, setEndIndex] = useState(BATCH);
    const [hasSearched, setHasSearched] = useState(false);
    
    // New state variables for counting executions
    const [fetchDataCount, setFetchDataCount] = useState(0);
    const [loadMoreDataCount, setLoadMoreDataCount] = useState(0);

    const { ref, inView } = useInView({
        threshold: 1,
        rootMargin: '100px',
    });

    const Sats = useMemo(() => {
        const sats = searchResults.length > 0 ? searchResults : satNos;
        const revSats = isDescending ? [...sats].reverse() : sats;
        return revSats.slice(0, endIndex);
    }, [endIndex, searchResults, satNos, isDescending]);

    useEffect(() => {
        const fetchData = async () => {
            if (Sats.length > 0 && isTokenResolved) {
                try {
                    const data = await fetchSatData(Sats);
                    setVisibleSats(isDescending ? Object.values(data).reverse() : Object.values(data));
                    setFetchDataCount(prevCount => prevCount + 1); // Increment fetchData count
                } catch (error) {
                    console.error("Error fetching satellite data:", error);
                }
            }
        };

        fetchData();
    }, [fetchSatData, isTokenResolved, Sats, isDescending]);

    const loadMoreData = useCallback(async () => {
        if (!isLoading && endIndex < (searchResults.length > 0 ? searchResults.length : satNos.length) && loadMoreDataCount < fetchDataCount) {
            try {
                setLoadMoreDataCount(prevCount => prevCount + 1); // Increment loadMoreData count
                setEndIndex(prevEnd => Math.min(prevEnd + BATCH, (searchResults.length > 0 ? searchResults.length : satNos.length)));
            } catch (error) {
                console.error("Error loading more satellite data:", error);
            }
        }
    }, [endIndex, isLoading, satNos.length, searchResults.length, fetchDataCount, loadMoreDataCount]);


    useEffect(() => {
        if (inView && !isLoading) {
            loadMoreData();
        }
    }, [inView, isLoading, loadMoreData]);

    const removeFilter = (key) => {
        const updatedFilters = Object.keys(filters)
            .filter(filterKey => filterKey !== key)
            .reduce((obj, key) => {
                obj[key] = filters[key];
                return obj;
            }, {});
        setFilters(updatedFilters);
    };

    const handleSearchChange = (event) => {
        setSearchValue(event.target.value);
    };

    const handleSearch = async () => {
        const results = await searchSats(filters, searchValue.split(',').map(str => str.trim()));
        setSearchResults(results);
        setEndIndex(BATCH);
        setHasSearched(true);
    };

    const isAscendDescend = () =>{
        return (isDescending
            ? <><LuArrowDownRight className="icon-lg" /> Descending</>
            : <><LuArrowUpRight className="icon-lg" /> Ascending</>);
    }

    const handleHrrToggle = () => {
        const newHrrChecked = !hrrChecked;
        setHrrChecked(newHrrChecked);

        if (newHrrChecked) {
            // Add filter for HrrRank > 0
            const newFilter = {
                "Rank": {
                    param: "Rank",
                    operator: ">",
                    value: 0
                }
            };
            setFilters(prevFilters => ({...prevFilters, ...newFilter}));
        } else {
            // Remove the HrrRank filter
            setFilters(prevFilters => {
                const updatedFilters = {...prevFilters};
                delete updatedFilters.Rank;
                return updatedFilters;
            });
        }
    };

    const onSatUpdate = async (satNo) => {
        try {
            const response = await fetchSatData([satNo]); // Fetch the satellite data
            setVisibleSats(prevVisibleSats => {
                const newSats = {...prevVisibleSats};
                newSats[satNo] = response[satNo];
                return newSats; // Update the visibleSats with the fetched data
        });
            setSelectedSat(response[satNo]); // Set the selected satellite
        } catch (error) {
            console.error("Error updating satellite data:", error);
        }
    };

    const addFilter = (newFilter) => {
        setFilters(prevFilters => ({ ...prevFilters, ...newFilter }));
    };
    
    return (
        <>
            <ModalEditData
                show={showSatModal}
                setShow={setShowSatModal}
                showFooter={true}
                selectedSat={selectedSat}
                onSatUpdate={onSatUpdate}
            />
            <Container style={{ paddingTop: "25px" }}>
                <div style={{ backgroundSize: 'cover', color: '#fff', textAlign: 'center', padding: '30px 0' }}>
                    <Container>
                        <Row>
                            <Col>
                                <Image width="400px" src={ProbeLogoPNG} alt="Probe Logo" fluid loading="eager" />
                            </Col>
                        </Row>
                    </Container>
                </div>

                <Row>
                    <Col sm="10">
                        <h4>Satellites {searchResults.length > 0 ? `(${searchResults.length})` : `(${satNos.length})`}</h4>
                    </Col>
                </Row>

                {/* SEARCH */}
                <Row className="mb-1">
                    <Col>
                        <Form.Group controlId="search" className="border border-top-0 rounded-3">
                            <InputGroup>
                                <Button
                                    variant="outline-secondary"
                                    title="Search Filters"
                                    onClick={() => setShowQueryModal(true)}
                                >
                                    <LuFilter style={{ fontSize: "1rem" }} /><LuList style={{ fontSize: "1.25rem" }} />
                                </Button>
                                <Button
                                    variant="warning"
                                    onClick={handleSearch}
                                    title="Search"
                                    className="search-button"
                                >
                                    <IoIosSearch style={{ fontSize: "1.25rem", color: "black" }} />
                                </Button>
                                <Form.Control
                                    required
                                    type="text"
                                    className="form-control-lg"
                                    name="search"
                                    value={searchValue}
                                    placeholder="Search (NoradId, Name)..."
                                    onChange={handleSearchChange}
                                    onKeyDown={(event) => {
                                        if (event.key === 'Enter') {
                                            event.preventDefault(); // Prevent the default form submission
                                            handleSearch(); // Call the search function
                                        }
                                    }}
                                />
                            </InputGroup>

                             {/* Applied Filters */}
                        {Object.entries(filters).length > 0 && (
                            <span>
                                <p style={{ margin: ".5rem" }}>Applied Filters:</p>
                                <ul style={{ paddingLeft: '0', listStyleType: 'none', display: 'flex', flexWrap: 'wrap', gap: '5px', margin: ".5rem" }}>
                                    {Object.entries(filters).map(([key, { operator, value, start, end }]) => {
                                        return (
                                            <li key={key} style={{ display: 'flex', alignItems: 'center' }}>
                                                <span className="bg-secondary"
                                                    style={{
                                                        marginRight: '.25rem',
                                                        backgroundColor: '#4D5656',
                                                        padding: '5px 10px',
                                                        borderRadius: '5px'
                                                    }}>
                                                    {key} {operator} {operator === "range" ? `from ${start} to ${end}` : `${value}`} 
                                                    <button className="btn btn-secondary" 
                                                    onClick={() => removeFilter(key)}
                                                    style={{padding:"0px",marginLeft:"4px",marginBottom:"4px"}}>
                                                        <LuX fontSize={"1.25rem"}/>
                                                    </button>
                                                </span>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </span>
                        )}
                        </Form.Group>
                        {hasSearched && searchResults.length === 0 && (searchValue || Object.keys(filters).length > 0) && (
                            <p style={{ color: 'red', marginTop: '10px', marginBottom: '0' }}>No matching satellites found</p>
                        )}
                    </Col>
                    <p style={{ paddingTop: "1rem" }}>
                        <LuInfo /> This is publicly available information and was not provided through Intel channels.
                    </p>
                </Row>

                <Row>
                <Col style={{ display: 'flex', alignItems: 'normal', gap:"10px"}}>
                {isLoggedIn && (
                    <>
                        <Button
                            type="checkbox"
                            className="mb-4"
                            variant={hrrChecked ? "success" : "light"}
                            onClick={handleHrrToggle}
                        >
                            HRR Only
                        </Button>
                    </>
                )}
                <Button
                    className="mb-4"
                    variant="light"
                    onClick={() => {setIsDescending(!isDescending); setEndIndex(BATCH)}}
                >
                    {isAscendDescend()}
                </Button>

                {isLoggedIn && (
                    <Button
                    className="mb-4"
                    variant="warning"
                    onClick={() => setShowAddSatModal(true)}
                    style={{marginLeft:"auto"}}
                    >
                        <div 
                        style={{verticalAlign:"center"}}>
                            <LuPlus/> Add Satellite
                        </div>
                    </Button>
                )}
                </Col>
                </Row>
                <SearchModal show={showQueryModal} setClose={() => setShowQueryModal(false)} addFilter={addFilter} filters={filters} removeFilter={() => {}} />
                {Object.keys(satNos).length === 0 && <Loading />}
                {visibleSats && (
                    <Grid container spacing={4} sx={{ justifyContent: 'center' }}>
                        {Object.values(visibleSats).map(sat => (
                            <Grid item key={sat?.SatNo ?? Math.random().toString()} xs={12} sm={12} md={6} lg={4}>
                                <SatelliteCard
                                    key={sat?.SatNo ?? Math.random().toString()}
                                    setShowSatModal={setShowSatModal}
                                    sat={sat}
                                    loggedIn={isLoggedIn}
                                    setSelectedSat={setSelectedSat}
                                />
                            </Grid>
                        ))}
                    </Grid>
                )}
                { isTokenResolved && !isLoading && <div ref={ref} style={{ height: '1px' }} />}
            </Container>
            <ModalAddSatellite show={showAddSatModal} setShow={setShowAddSatModal} />
        </>
    );
};

export default Home;

