import React, { useState, useCallback } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import About from './pages/About';
import Dossier from './pages/Dossier';
import DossierMin from './pages/DossierMin';
import Home from './pages/Home';
import ModalLogin from './components/ModalLogin';
import { ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useUser } from "./contexts/user";


const MainContent = ({
    setShowLoginModal
}) => {
    const location = useLocation();
    const {headerToken} = useUser();
    const hideHeaderFooter = /^\/dossier-min\/\d+/.test(location.pathname) || headerToken;

    return (
        <>
            <ToastContainer />
            {!hideHeaderFooter && <Header  
                setShowLoginModal={setShowLoginModal}
            />}
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/about" element={<About />} />
                <Route path="/dossier/:id" element={<Dossier />} />
                <Route path="/dossier-min/:id" element={<DossierMin />} />
            </Routes>
            {!hideHeaderFooter && <Footer />}
        </>
    );
};

const Main = () => {
    const {username, login} = useUser();
    const [showLoginModal, setShowLoginModal] = useState(false);
    const [disabled, setDisabled] = useState(false);

    // Use useCallback to stabilize the function reference
    const handleLogin = useCallback(async (username, password) => {
        setDisabled(true);
        await login(username, password);
        setShowLoginModal(false);
    }, [login]);
    
    return (
        <Router>
            <MainContent
                setShowLoginModal={setShowLoginModal}
                username={username}
            />
            <ModalLogin 
                show={showLoginModal} 
                setShow={setShowLoginModal} 
                handleLogin={handleLogin}  // Ensure this is being passed correctly
                disabled={disabled} 
            />
        </Router>
    );
};

export default Main;