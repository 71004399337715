import React, {useState} from "react";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {Modal, Container, Form, Row, Col, Button} from "react-bootstrap";
import { LiaMapMarkerAltSolid } from "react-icons/lia";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import "dayjs/locale/en"
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { TextField } from "@mui/material";
import { useUser} from "../contexts/user";
import '../css/poi.css';
import { createSatRow } from "../utils/Api";

const ModalAddPoi = ({
    show,
    setShow,
    sat,
    onUpdateSat,
}) => {
    const { username } = useUser();
    const [timestamp, setTimestamp] = useState(null);
    const [error, setError] = useState(false);
    const [saving, setSaving] = useState(false);
    const [updateSuccess, setUpdateSuccess] = useState(null);

    dayjs.extend(utc);


    // Create a theme instance
    const darkTheme = createTheme({
        palette: {
        mode: 'dark',
        },
    });

    const handleClose = () => {
        setShow(false);
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        const data = new FormData(e.target);
        const poiVal = data.get("value");
        const source = data.get("source");
        
        if (!timestamp || !poiVal || !source) {
            setError(true);
            return;
        }
        setError(false);

        if (timestamp && poiVal) {
            
            const newPOI = {
                SatNo: String(sat?.SatNo),
                Scheme: "poi",
                Section: "POI",
                VerifiedBy: username,
                Value: poiVal,
                Timestamp: timestamp,
                Source: source,
            };

            setSaving(true);
            setUpdateSuccess(<div style={{ color: "lightblue" }}>Saving...</div>);

            try {
                const response = await createSatRow(newPOI);
                if (response.success === false) {
                    setUpdateSuccess(<div style={{ color: "red" }}>Error: {response.error}</div>);
                } else {
                    setUpdateSuccess(<div style={{ color: "green" }}>POI saved successfully!</div>);
                    await onUpdateSat();
                    setShow(false);
                }
            } catch (error) {
                setUpdateSuccess(<div style={{ color: "red" }}>Failed to save POI: {error.message}</div>);
            } finally {
                setSaving(false);
            }
        }
    }
    return (
        <ThemeProvider theme={darkTheme}>
            <Modal
                className="poi-modal"
                show={show}
                onHide={handleClose}
                fullscreen={true}
            >
                <Modal.Header closeButton >
                    <Modal.Title>
                        <LiaMapMarkerAltSolid /> Create POI
                    </Modal.Title>
                </Modal.Header>
                
                <Modal.Body style={{padding:0, overflow:"hidden"}}>
                    <Container>
                        <Row className="pt-4">
                            <Col>
                                <Form id="poiForm" onSubmit={handleSubmit}>
                                    <Form.Group className="mb-3" controlId="poi">
                                        <LocalizationProvider
                                            dateAdapter={AdapterDayjs}
                                            adapterLocale="en"
                                        >
                                            <DateTimePicker
                                                name="timestamp"
                                                color="warning"
                                                label="Timestamp"
                                                slotProps={{textField: {fullWidth: true}}}
                                                timezone="UTC"
                                                className="mb-2"
                                                onChange={(newValue) => setTimestamp(newValue)}
                                                disableFuture
                                                format="YYYY-MM-DD HH:mm:ss"
                                                required
                                                ampm={false}
                                            />
                                            {error && <div className="pb-3" style={{ color: 'red' }}>Timestamp is required</div>} 
                                        </LocalizationProvider>
                                        <TextField
                                            className="mb-2"
                                            style={{width:"100%"}}
                                            label="Source URL"
                                            name="source"
                                            type="url"
                                            placeholder="URL of where you found this information"
                                            multiline
                                            required
                                        />
                                        
                                        <TextField 
                                            className="mb-2"
                                            minRows={3}
                                            style={{width:"100%"}}
                                            name="value"
                                            label="Value" 
                                            placeholder="POI details, e.g Spacecraft maneuvered with +i2m/s dV."
                                            multiline
                                            required
                                        />

                                        <div className="mb-2">
                                            Verified by: <b>{username}</b> 
                                        </div>
                                        <br/>
                                        <Button
                                            type="submit"
                                            variant="warning"
                                            style={{
                                                width:"150px"
                                            }}
                                            disabled={saving}
                                        >
                                            Save
                                        </Button>
                                        {updateSuccess}
                                    </Form.Group>

                                </Form>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>
        </ThemeProvider>
    );
};
export default ModalAddPoi;
